body {
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  font-family: usual;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: auto;
}

h1, p, h2 {
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family:"graphik";
  src:url("./theme/fonts/Graphik-Regular.woff2") format("woff2"),url("./theme/fonts/Graphik-Regular.woff") format("woff"),url("./theme/fonts/Graphik-Regular.otf") format("opentype");
  font-display:auto;
  font-style:normal;
  font-weight:400;
  }

  /* unvisited link */
a:link {
  color: white;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: white;
  text-decoration: none;
}

/* mouse over link */
a:hover {
  color: white;
  text-decoration: underline;
}

/* selected link */
a:active {
  color: white;
  text-decoration: none;
}
