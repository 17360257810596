.meditation{
    overflow-y: hidden;
}

.big {
    margin-top: 0;
    font-size: 4vw ; 
    font-family: 'usual';
    line-height: 1.2;
}

.small {
    margin-top: 0;
    font-size: 2vw ; 
    font-family: 'usual';
    line-height: 1.2;
}

#demo-simple-select {
    padding: 0;
}